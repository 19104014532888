<template>
  <div class="animated fadeIn container">
    <h1>충전 내역</h1>
    <table class="border-table mt-4" v-if="items.length>0">
      <thead>
        <tr>
          <td width="20%">요청시각</td>
          <td width="50%">입금자명</td>
          <td width="20%">금액</td>
          <td width="20%">상태</td>
        </tr>
      </thead>
      <tbody>
        <tr :key=item.id v-for="item in items">
          <td>
            <span class="mr-3">{{ item.createdAt | dateFormat }}</span>
          </td>
          <td>
            {{ item.depositor }}
          </td>
          <td>
            {{ item.money | numberFormat }} 원
          </td>
          <td>
            {{ statusText(item.status) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import DepositService from '@/services/DepositService'

  export default {
    name: 'DepositHistory',
    data() {
      return {
        items: []
      }
    },
    mounted() {
      document.getElementsByClassName('app-body')[0].classList.add('bg-white')

      this.getRequests()
    },
    methods: {
      async getRequests() {
        const response = await DepositService.getRequests()
        if (response.status == 200) {
          this.items = response.data.list
        }
      },
      statusText(status) {
        if (status == 0) {
          return '승인대기'
        } else if (status == 1) {
          return '승인거절'
        } else if (status == 2) {
          return '승인완료'
        } else {
          return '알수없음'
        }
      }
    }
  }

</script>
